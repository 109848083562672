import React from "react";
import Layout from "../components/_common/layout";
import Container from "../components/_common/Container";
import { Link } from "gatsby";

const NotFoundPage = () => (
  <Layout title="Page introuvable" subsection="404">
    <Container>
      <h1>Page introuvable</h1>
      <p>La page que vous cherchez à atteindre n'existe pas.</p>
      <Link to="/">Revenir à l'accueil</Link>
    </Container>
  </Layout>
);

export default NotFoundPage;
